import { createPinia } from "pinia";
import { createStore } from 'vuex'
import getters from './getters'
import user from './modules/user1'
import { actions } from "./modules/user1";
import permission from './modules/permission1'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import CreatePersistedState from 'vuex-persistedstate'
const store = createPinia();
export function setupStore(app: any) {
  store.use(piniaPluginPersistedstate);
  app.use(store);
}

const vuexPersisted = CreatePersistedState({
  key: 'dop-web',
  storage: window.sessionStorage,
  reducer: (state: any) => ({
    user: state.user,
    permission: state.permission
  })
})

const stroe1 = createStore({
  modules: {
    user,
    permission
  },
  getters,
  plugins: [vuexPersisted]
})

export { store };

export default stroe1
