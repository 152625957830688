import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const dataReportRouter = {
  path: "/transaction-analysis",
  component: Layout,
  redirect: "/transaction-analysis/data-report",
  meta: {
    icon: "2",
    title: $t("交易分析"),
    rank: 2,
    roles: ["koms.report"]
  },
  children: [
    {
      path: "/transaction-analysis/data-report",
      name: "DataReport",
      component: () => import("/@/views/data-report/index.vue"),
      meta: {
        title: $t("经营报告"),
        showParent: true
      }
    }
  ]
};

export default dataReportRouter;
