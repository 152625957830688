import { ElMessageBox, ElMessage } from "element-plus";
import { i18n } from "/@/plugins/i18n";
const { t } = i18n.global;
// 提示
export function showWarning(back: any, title?: string, text?: string) {
  ElMessageBox.confirm(
    `<div class="msg">${text ? text : t("fireText")}</div><div class="tis">${t(
      "是否继续操作"
    )}</div>`,
    title ? title : t("fireTitle"),
    {
      confirmButtonText: t("confirm"),
      cancelButtonText: t("no"),
      showClose: false,
      customClass: "my-confirm",
      dangerouslyUseHTMLString: true
    }
  ).then(() => {
    back();
  });
}

// 用于重新登录
export function showConfirm(back: any, title?: string, text?: string) {
  ElMessageBox.confirm(
    `<div class="msg">${text ? text : t("fireText")}</div>`,
    title ? title : t("fireTitle"),
    {
      confirmButtonText: t("重新登录"),
      showCancelButton: false,
      showClose: false,
      customClass: "my-confirm",
      dangerouslyUseHTMLString: true,
      autofocus: false
    }
  ).then(() => {
    back();
  });
}
// 用于提示没有权限
export function showConfirm2(back: any, title?: string, text?: string) {
  ElMessageBox.confirm(
    `<div class="msg">${text ? text : t("fireText")}</div>`,
    title ? title : t("fireTitle"),
    {
      confirmButtonText: t("确认"),
      showCancelButton: false,
      showClose: false,
      customClass: "my-confirm",
      dangerouslyUseHTMLString: true,
      autofocus: false
    }
  )
}

// 操作成功
export function showSuccess() {
  ElMessage.success(t("success"));
}
// 操作失败
export function showWarning2() {
  ElMessage.warning(t("warning"));
}
