import { defineStore } from "pinia";
import { store } from "/@/store";
// import { usersType } from "./types";
import router, { resetRouter } from "/@/router";
import {
  getToken,
  setToken,
  removeToken,
  setRefreshToken,
  removeAuthCode,
  setCurrentTimestamp,
  setExpiresTimestamp,
  getLocalMsTime
} from "/@/utils/auth";
import {
  getTokenApi,
  gotoAuthCenter,
  refreshTokenApi,
  logoutApi
} from "/@/api/auth-token";
import {
  login,
  getAuths,
  getCustomerByUserId,
  getStoreByUserIdReal,
  getCustomerByUserIdReal
} from "/@/api/user";
import { getRoleDetailsByCode } from "/@/api/organization";
import asyncRoutes from "/@/router/modules/asyncRoutes";
import remainingRouter from "/@/router/modules/remaining";
import {
  ascending,
  filterTree,
  formatTwoStageRoutes,
  formatFlatteningRoutes
} from "/@/router/utils";
import { buildHierarchyTree } from "/@/utils/tree";
import { storageSession } from "/@/utils/storage";

const token = getToken();

export const useUserStore = defineStore({
  id: "dop",
  persist: {
    storage: window.sessionStorage
  },
  state: () => ({
    token: token,
    userInfo: {},
    name: "",
    userId: "",
    accountId: "",
    groupId: "",
    customerId: "",
    avatar: "",
    introduction: "",
    roles: [],
    acquirerId: "",
    acquirerName: "",
    selectedRole: "",
    rolesAdd: [],
    routes: [],
    addRoutes: [],
    type: "Web;koms/1",
    storeId: "",
    storeList: []
  }),
  actions: {
    SET_USER_INFO(userInfo: any) {
      this.userInfo = userInfo;
    },
    SET_TOKEN(token: string) {
      this.token = token;
    },
    SET_STORE: (state, storeId) => {
      state.storeId = storeId;
    },
    SET_INTRODUCTION(introduction: string) {
      this.introduction = introduction;
    },
    SET_USERID(userId: string) {
      this.userId = userId;
    },
    SET_GROUPID(groupId: string) {
      this.groupId = groupId;
    },
    SET_ACCOUNTID(accountId: string) {
      this.accountId = accountId;
    },
    SET_CUSTOMERID(customerId: string) {
      this.customerId = customerId;
    },
    SET_AVATAR(avatar: string) {
      this.avatar = avatar;
    },
    SET_ROLES(roles: any) {
      this.roles = roles;
    },
    SET_ACQUIRERID(id: string) {
      this.acquirerId = id;
    },
    SET_ACQUIRERNAME(name: string) {
      this.acquirerName = name;
    },
    SET_SELECTEDROLE(code: string) {
      this.selectedRole = code;
    },
    SET_ADDROLES(roles: any) {
      this.rolesAdd = roles;
    },
    SET_STOREID(storeId: string) {
      this.storeId = storeId;
    },
    SET_STORELIST(state, storeList) {
      state.storeList = storeList;
    },
    // 获取token
    getToken(data: any) {
      return new Promise((resolve, reject) => {
        getTokenApi(data)
          .then(response => {
            const { result } = response;
            if (!result || typeof result === "undefined") {
              return reject("getToken 失败");
            }
            const { access_token, refresh_token, expires_in } = result;
            setToken(access_token);
            setRefreshToken(refresh_token);
            setCurrentTimestamp(getLocalMsTime()); // 储存当前时间戳
            setExpiresTimestamp(
              getLocalMsTime() + expires_in * 1000 - 300 * 1000
            ); // 储存过期时间戳
            resolve(result);
          })
          .catch(error => {
            console.log(error.message);
            // 调用授权中心进行授权
            reject(error);
          });
      });
    },

    // 刷新token
    refreshToken(data: any) {
      return new Promise((resolve, reject) => {
        refreshTokenApi(data)
          .then(response => {
            const { result,code } = response;
            if (!result || typeof result === "undefined" || code=== 50000) {
              return reject("refreshToken 失败");
            }
            const { access_token, refresh_token, expires_in } = result;
            // 储存token
            setToken(access_token);
            // 储存refreshToken
            setRefreshToken(refresh_token);
            // 储存当前时间戳
            setCurrentTimestamp(getLocalMsTime());
            // 储存过期时间戳
            setExpiresTimestamp(
              getLocalMsTime() + expires_in * 1000 - 300 * 1000
            );
            resolve(result);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 获取授权
    getAuths() {
      const that = this;
      return new Promise((resolve, reject) => {
        getAuths()
          .then(async response => {
            const { result, message } = response;

            if (!result || typeof result === "undefined") {
              return reject(message || "获取授权失败");
            }
            that.userInfo = result;
            // SET_USER_INFO(result)
            // commit('SET_USER_INFO', result)
            resolve({ user: result });
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    //获取真正的权限
    getRealAuths({ commit, state }) {
      const that = this;
      return new Promise((resolve, reject) => {
        let code = "";
        if (that.selectedRole) {
          code = that.selectedRole;
        } else {
          const allRoleList = storageSession.getItem("allRoleList");
          allRoleList.forEach(item => {
            if (state.userInfo.roles.indexOf(item.code) !== -1) {
              code = item.code;
            }
          });
          if (!code) {
            // 非机构管理员和非平台管理员
            router.push("/402");
            return;
          }
          commit("SET_SELECTEDROLE", code);
          getRoleDetailsByCode(code)
            .then(async response => {
              const permissionCodes = response.result.permissionCodes;
              const code = response.result.code;

              if (!response.result || typeof response.result === "undefined") {
                return reject(message || "获取授权失败");
              }
              // roles must be a non-empty array
              if (permissionCodes === null) {
                // commit('SET_ROLES', null)
                that.roles = [];
                return reject("getAuths: roles must be a non-null array!");
              }
              // commit('SET_ROLES', permissionCodes)
              that.roles = permissionCodes;
              resolve({ roles: permissionCodes, type: code });
            })
            .catch(error => {
              reject(error);
            });
        }
      });
    },
    // 获取商户信息
    getCustom() {
      const that = this;
      return new Promise((resolve, reject) => {
        getCustomerByUserId()
          .then(response => {
            if (response.code === 20000) {
              that.customerId = response.result.customerId;
              // that.storeId = response.result.merId;
              resolve({ customInfo: response });
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // user login
    login(userInfo: any) {
      const { username, password } = userInfo;
      return new Promise((resolve, reject) => {
        login({ username: username.trim(), password: password })
          .then(async response => {
            const result = response;
            // commit('SET_TOKEN', result.authInfo)
            setToken(result.authInfo);

            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 通过用户ID获取商户
    getCustomReal({ commit, state }) {
      return new Promise((resolve, reject) => {
        getCustomerByUserIdReal({ userId: state.userId })
          .then(response => {
            console.log(response);
            if (response.result) {
              commit("SET_CUSTOMERID", response.result.customerId);
              // commit("SET_STOREID", response.result.merId);
              resolve({ customerId: response.result.customerId });
            } else {
              // commit("SET_CUSTOMERID", "");
              // commit("SET_STOREID", "");
              resolve({ customerId: "" });
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    // 通过用户ID获取门店信息
    getStoreReal({ commit, state }) {
      return new Promise((resolve, reject) => {
        getStoreByUserIdReal({ userId: state.userId })
          .then(response => {
            console.log('response',response);
            if (response.result) {
              commit("SET_CUSTOMERID", response.result[0].customerId);
              // commit("SET_STOREID", response.result[0].storeId);
              // commit("SET_STOREID", checkStore(response.result));
              commit("SET_STORELIST", response.result);
              resolve({ storeList: response.result.storeList });
            } else {
              // commit("SET_CUSTOMERID", "");
              // commit("SET_STOREID", "");
              // commit("SET_STORELIST", []);
              resolve({ storeList: [] });
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // user logout
    logout() {
      const that = this;
      return new Promise((resolve, reject) => {
        logoutApi()
          .then(response => {
            const { result } = response;
            if (!result || typeof result === "undefined") {
              return reject("退出失败");
            }
            commit("SET_TOKEN", "");
            commit("SET_ROLES", []);
            removeToken();
            resetRouter();
            gotoAuthCenter();
            console.log("退出成功");
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    // remove token
    resetToken() {
      const that = this;
      return new Promise(resolve => {
        that.token = "";
        that.roles = [];
        that.selectedRole = "";
        // commit('SET_TOKEN', '')
        // commit('SET_ROLES', [])
        // commit('SET_SELECTEDROLE', '')
        removeToken();
        resetRouter();
        removeAuthCode();
        resolve();
      });
    },

    generateRoutes(roles: any) {
      const that = this;
      return new Promise(resolve => {
        const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
        that.addRoutes = accessedRoutes;
        const constantRoutes = formatTwoStageRoutes(
          formatFlatteningRoutes(buildHierarchyTree(ascending(accessedRoutes)))
        );
        that.routes = filterTree(
          ascending(remainingRouter.concat(accessedRoutes))
        );
        that.rolesAdd = constantRoutes;
        resolve(constantRoutes);
      });
    }
  }
});

function hasPermission(roles: any, route: any) {
  if (route.meta && route.meta.roles) {
    return roles.some((role: any) => route.meta.roles.includes(role));
  } else {
    return true;
  }
}

function checkStore(arr) {
  let storeId = arr[0].storeId;
  arr?.forEach(item => {
    console.log('item.storeId1',item.storeId);
    if (item.status === "0") {

      storeId = item.storeId;
    }
  });
  console.log('item.storeId2',storeId);

  return storeId;
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes: any, roles: any) {
  const res = [];
  routes.forEach((route: any) => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

export function useUserStoreHook() {
  return useUserStore(store);
}
