import asyncRoutes from "/@/router/modules/asyncRoutes"
import { ascending, filterTree, formatTwoStageRoutes, formatFlatteningRoutes } from "/@/router/utils"
import { buildHierarchyTree } from "/@/utils/tree"
import remainingRouter from "/@/router/modules/remaining"
import { router } from "/@/router";
import { cloneDeep } from "lodash-unified";


/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles) {
  const res = []
  routes.forEach(route => {
    const tmp = { ...route }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles)
      }
      res.push(tmp)
    }
  })
  return res
}

const state = {
  routes: [],
  addRoutes: [],
  roles: [],
  rolesAdd: [],
}

const mutations = {
  SET_ADDROUTES: (state, routes) => {
    state.addRoutes = routes
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_ROUTES: (state, routes) => {
    state.routes = routes
  },
  SET_ROLESADD: (state, routes) => {
    state.rolesAdd = routes
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    // return new Promise(resolve => {
    //   const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
    //   commit('SET_ADDROUTES', accessedRoutes)
    //   const constantRoutes = formatTwoStageRoutes(formatFlatteningRoutes(buildHierarchyTree(ascending([homeRouter]).concat(...accessedRoutes))))
    //   commit('SET_ROUTES', accessedRoutes)
    //   commit('SET_ROLESADD', constantRoutes)
    //   resolve(accessedRoutes)
    // })
    const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles);
    // console.log('roles',roles);
    // console.log('asyncRoutes',asyncRoutes);
    
    // console.log('accessedRoutes',accessedRoutes);
    
    commit('SET_ADDROUTES', accessedRoutes)
    const constantRoutes = formatTwoStageRoutes(formatFlatteningRoutes(buildHierarchyTree(ascending(accessedRoutes))))
    commit('SET_ROUTES', filterTree(ascending(remainingRouter.concat(accessedRoutes))))
    // console.log('accessedRoutes',filterTree(ascending(remainingRouter.concat(accessedRoutes))));
    
    commit('SET_ROLESADD', constantRoutes)
    return constantRoutes
  },
  getRoutes(roles) { 
    const accessedRoutes = filterAsyncRoutes(asyncRoutes, roles)
    return accessedRoutes
  },
  // initRouter(roles?) {
  //   if (roles) {
  //     this.setRoles(roles);
  //     // 解决权限更新菜单不更新问题
  //     this.wholeMenus = [];
  //   }
  //   return new Promise(resolve => {
  //     if (this.wholeMenus.length > 0) return;
  //     this.wholeMenus = filterTree(ascending(this.constantMenus));
  //     console.log("=== initRouter current roles: ", this.roles);
  //     const accessedRoutes = filterAsyncRoutes(this.wholeMenus, this.roles);
  //     this.wholeMenus = accessedRoutes;
  //     router.addRoute({
  //       path: "/:pathMatch(.*)",
  //       redirect: "/error/404"
  //     });

  //     this.menusTree = cloneDeep(
  //       filterTree(ascending(this.constantMenus.concat(accessedRoutes)))
  //     );

  //     const getButtonAuth = (arrRoutes) => {
  //       if (!arrRoutes || !arrRoutes.length) return;
  //       arrRoutes.forEach((v) => {
  //         if (v.meta && v.meta.authority) {
  //           this.buttonAuth.push(...v.meta.authority);
  //         }
  //         if (v.children) {
  //           getButtonAuth(v.children);
  //         }
  //       });
  //     };

  //     getButtonAuth(this.wholeMenus);

  //     // this.changeSetting(accessedRoutes);

  //     resolve(router);
  //   });
  // },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
