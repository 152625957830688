const getters = {
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  userId: state => state.user.userId,
  accountId: state => state.user.accountId,
  groupId: state => state.user.groupId,
  customerId: state => state.user.customerId,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  routes: state => state.permission.routes,
  permission_routes: state => state.permission.rolesAdd,
  errorLogs: state => state.errorLog.logs,
  acquirerId: state => state.user.acquirerId,
  acquirerName: state => state.user.acquirerName,
  selectedRole: state => state.user.selectedRole,
  isFrist: state => state.user.isFrist,
  id: state => state.user.id,
  type: state => state.user.type,
  storeId: state => state.user.storeId,
  storeList: state => state.user.storeList
};
export default getters;
