import request from '/@/utils/request'
//平台获取机构列表
export function orgsList() {
  return request({
    url: '/acquirer/listAllAcquirer',
    method: 'get'
  })
}
//删除平台机构
export function deleteOrg(id) {
  return request({
    url: `/acquirer/${id}`,
    method: 'delete'
  })
}
//查看平台机构
export function viewOrg(id) {
  return request({
    url: `/acquirer/${id}`,
    method: 'get'
  })
}
//新增平台机构
export function addOrg(data) {
  return request({
    url: `/acquirer`,
    method: 'post',
    data: data
  })
}
//修改平台机构
export function updateOrg(id, data) {
  return request({
    url: `/acquirer/${id}`,
    method: 'patch',
    data: data
  })
}
//获取登录用户选择角色的orgID
export function getAcquirerIdByUser(code) {
  return request({
    url: `/acquirer/getAcquirerIdByUser`,
    method: 'get',
    params: code
  })
}
//获取登录用户选择角色的权限
export function getRoleDetailsByCode(code) {
  return request({
    url: `/rolemgr/getRoleDetailsByCode/${code}`,
    method: 'get',
  })
}
//获取机构产品列表
export function getorgProductList(params) {
  return request({
    url: `/product/listProduct`,
    method: 'get',
    params
  })
}
//机构绑定产品
export function orgBindpro(data) {
  return request({
    url: `/acquirer/launchProduct`,
    method: 'post',
    data: data
  })
}
//获取原始产品配置
export function getLeftOrginfo(id) {
  return request({
    url: `/product/detail/${id}`,
    method: 'get'
  })
}
//获取新的产品配置
export function getRightOrginfo(acquirerId) {
  return request({
    url: `/acquirer/${acquirerId}`,
    method: 'get'
  })
}

//获取新的产品配置
export function getAcquirerList(params) {
  return request({
    url: `/acquirer`,
    method: 'get',
    params
  })
}
