// import axios from 'axios'
import request from '/@/utils/request'
import { setStateCodeKey, getLanguage } from '/@/utils/auth'
import { useUserStoreHook } from "/@/store/modules/user";
import { storageLocal } from "/@/utils/storage"
import $store from "/@/store/index";

// 获取token
export function getTokenApi(data) {
  return request({
    url: `/oauth/token/`,
    method: 'post',
    params: { withoutToken: true },
    data: data
  })
}

// 刷新token
export function refreshTokenApi(data) {
  return request({
    url: `/oauth/refresh_token/`,
    method: 'post',
    params: { withoutToken: true },
    data: data
  })
}



// 授权中心-心跳接口
export function keepAliveApi() {
  // axios.get(window._CONFIG['aliveURL']).then(() => {
  //   console.log('保持心跳')
  // })
  return request({
    baseURL: window._CONFIG['aliveURL'],
    params: { withoutToken: true },
    method: 'get'
  })
}

// 截取某一特定param的值
export function getParam(param) {
  var reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

// 随机生成6位大写字母组成的字符串
export function stateCode() {
  let str = ''

  for (let i = 0; i < 6; i++) {
    str += String.fromCharCode(Math.floor(Math.random() * 26 + 65))
  }
  return str
}

// 跳转到授权中心
export function gotoAuthCenter() {
  // $store.dispatch("user/resetToken");
  // 随机生成一个stateCode
  const stateCodeNew = stateCode()
  // 储存stateCode，用来下一次对比
  setStateCodeKey(stateCodeNew)
  // 调用授权中心进行授权
  window.location.href = window._CONFIG['authURL']
  // window.location.href = window._CONFIG['authURL'] + '&lang=' + (getLanguage() || 'zh_CN') + '&roleCode=' + useUserStoreHook().selectRole
}

// 退出登录
export function logoutApi() {
  return request({
    xhrFields: {
      withCredentials: true
    },
    baseURL: window._CONFIG['logoutURL'],
    // params: { withoutToken: true },
    method: 'get'
  })
}

// 判断当前账号是否激活过
export function checkAccount(type: any, accountName: any) {
  return request({
    url: `/customer-account/checkAccount/${type}/${accountName}`,
    method: 'get'
  })
}

