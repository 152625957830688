import { http } from "../utils/http";
import request from "/@/utils/request";

interface userType extends Promise<any> {
  svg?: string;
  code?: number;
  info?: object;
}

// 获取验证码
export const getVerify = (): userType => {
  return http.request("get", "/captcha");
};

// 登录
export const getLogin = (data: object) => {
  return http.request("post", "/login", { data });
};

// 刷新token
export const refreshToken = (data: object) => {
  return http.request("post", "/refreshToken", { data });
};

// 获取用户权限
export function getAuths() {
  return request({
    baseURL: window._CONFIG["userURL"],
    url: `/users/current`,
    method: "get"
  });
}

// 获取商户信息
export function getCustomerByUserId() {
  return request({
    baseURL: window._CONFIG["DOPURL"],
    url:
      `/customer/getCustomerByUserId?productIds=` +
      window._CONFIG["productCode"],
    method: "get"
  });
}

// 通过用户ID获取商户
export function getCustomerByUserIdReal(params) {
  return request({
    url: "/Employee/getCustomerByUserId",
    method: "get",
    params
  });
}
// 通过用户ID获取商户
export function getStoreByUserIdReal(params) {
  return request({
    url: "/Employee/getStoreByUserId",
    method: "get",
    params
  });
}

// 重置密码
export function initPassword(userId, data) {
  return request({
    baseURL: window._CONFIG["userURL"],
    url: `/account/init-password/${userId}`,
    method: "PATCH",
    params: { withoutToken: true },
    data
  });
}

export function login(data) {
  return request({
    url: "/user/login",
    method: "post",
    data: data
  });
}

export function getUserInfo() {
  return request({
    url: `/user/detail`,
    method: "get"
  });
}

export function logout() {
  return request({
    url: "/user/logout",
    method: "post"
  });
}

// 列表查询用户
export function fetchUser(query) {
  return request({
    url: "/user/query",
    method: "post",
    data: query
  });
}

// 创建用户
export function addUser(data) {
  return request({
    url: "/user/add",
    method: "post",
    data: data
  });
}

// 修改用户
export function updateUser(data, id) {
  return request({
    url: `/user/modify/` + id,
    method: "put",
    data: data
  });
}

// 查看用户
export function ViewUser(id) {
  return request({
    url: "/user/detail/" + id,
    method: "get"
  });
}
// 删除用户
export function deleteUser(userId) {
  return request({
    url: `/user/delete/` + userId,
    method: "put"
  });
}

// 重置用户accessKey
export function resetUserSecret(data) {
  return request({
    url: "/user/key/reset",
    method: "put",
    data: data
  });
}
// 查看用户accessKey
export function fetchUserSecret(data) {
  return request({
    url: "/user/key/query",
    method: "post",
    data: data
  });
}

// 修改密码
export function updatePassword(data) {
  return request({
    url: `/user/passwd`,
    method: "put",
    data: data
  });
}

export function add(data) {
  return request({
    url: "/users",
    method: "post",
    data
  });
}

export function downloadUser(params) {
  return request({
    url: "/users/download",
    method: "get",
    params,
    responseType: "blob"
  });
}

export function del(id) {
  return request({
    url: "/users/" + id,
    method: "delete"
  });
}

export function edit(data) {
  return request({
    url: "/users",
    method: "put",
    data
  });
}

export function updatePass(user) {
  const data = {
    oldPass: user.oldPass,
    newPass: user.newPass
  };
  return request({
    url: "/users/updatePass/",
    method: "post",
    data
  });
}

export function updateEmail(code, data) {
  return request({
    url: "/users/updateEmail/" + code,
    method: "post",
    data
  });
}
// 查看当前用户
export function ViewCurrentUser() {
  return request({
    url: "/user/detail",
    method: "get"
  });
}
// 修改当前用户
export function updateCurrentUser(data) {
  return request({
    url: `/user/modify`,
    method: "put",
    data: data
  });
}
