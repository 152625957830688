import {
  login,
  getAuths,
  getCustomerByUserId,
  getCustomerByUserIdReal,
  getStoreByUserIdReal
} from "/@/api/user";
import {
  getToken,
  setToken,
  removeToken,
  setRefreshToken,
  removeAuthCode,
  setCurrentTimestamp,
  setExpiresTimestamp,
  getLocalMsTime
} from "/@/utils/auth";
import { init } from "/@/api/home";
import router, { resetRouter } from "/@/router";
import {
  getTokenApi,
  gotoAuthCenter,
  refreshTokenApi,
  logoutApi,
  checkAccount
} from "/@/api/auth-token";
import { getRoleDetailsByCode } from "/@/api/organization";
import { storageSession } from "/@/utils/storage";

const state = {
  token: getToken(),
  // token: '',
  userInfo: {},
  name: "",
  userId: "",
  accountId: "",
  groupId: "",
  customerId: "",
  avatar: "",
  introduction: "",
  roles: [],
  acquirerId: "",
  acquirerName: "",
  selectedRole: "",
  isFrist: true,
  id: "",
  type: "Web;koms/1",
  storeId: "",
  storeList: []
};

const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_STORE: (state, storeId) => {
    state.storeId = storeId;
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction;
  },
  SET_NAME: (state, name) => {
    state.name = name;
  },
  SET_USERID: (state, userId) => {
    state.userId = userId;
  },
  SET_GROUPID: (state, groupId) => {
    state.groupId = groupId;
  },
  SET_ACCOUNTID: (state, accountId) => {
    state.accountId = accountId;
  },
  SET_CUSTOMERID: (state, customerId) => {
    state.customerId = customerId;
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_ACQUIRERID: (state, id) => {
    state.acquirerId = id;
  },
  SET_ACQUIRERNAME: (state, name) => {
    state.acquirerName = name;
  },
  SET_SELECTEDROLE: (state, code) => {
    state.selectedRole = code;
  },
  SET_FRIST: (state, code) => {
    state.isFrist = code;
  },
  SET_ID: (state, id) => {
    state.id = id;
  },
  SET_STOREID(state, storeId) {
    state.storeId = storeId;
  },
  SET_STORELIST(state, storeList) {
    state.storeList = storeList;
  }
};

const actions = {
  // 获取token
  getToken({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      getTokenApi(data)
        .then((response: any) => {
          const { result } = response;
          if (!result || typeof result === "undefined") {

            return reject("getToken 失败");
          }
          const { access_token, refresh_token, expires_in, user_id } = result;
          commit("SET_USERID", user_id);
          console.log('获取token');

          setToken(access_token);
          setRefreshToken(refresh_token);
          setCurrentTimestamp(getLocalMsTime()); // 储存当前时间戳
          setExpiresTimestamp(
            getLocalMsTime() + expires_in * 1000 - 300 * 1000
          ); // 储存过期时间戳
          resolve(result);
        })
        .catch(error => {
          console.log(error.message);
          // 调用授权中心进行授权
          reject(error);
        });
    });
  },

  // 刷新token
  refreshToken({ commit, state }, data) {
    return new Promise((resolve, reject) => {
      refreshTokenApi(data)
        .then((response: any) => {
          const { result } = response;
          if (!result || typeof result === "undefined") {
            return reject("refreshToken 失败");
          }
          const { access_token, refresh_token, expires_in, user_id } = result;
          commit("SET_USERID", user_id);
          // 储存token
          console.log('刷新token');
          setToken(access_token);
          // 储存refreshToken
          setRefreshToken(refresh_token);
          // 储存当前时间戳
          setCurrentTimestamp(getLocalMsTime());
          // 储存过期时间戳
          setExpiresTimestamp(
            getLocalMsTime() + expires_in * 1000 - 300 * 1000
          );
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // 获取授权
  getAuths({ commit, state }) {
    return new Promise((resolve, reject) => {
      getAuths()
        .then(async (response: any) => {
          const { result, message } = response;

          if (!result || typeof result === "undefined") {
            return reject(message || "获取授权失败");
          }

          commit("SET_USER_INFO", result);

          resolve({ user: result });
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  //获取真正的权限
  getRealAuths({ commit, state }) {
    return new Promise((resolve, reject) => {
      let code = "";
      if (state.selectedRole) {
        code = state.selectedRole;
      } else {
        const allRoleList = storageSession.getItem("allRoleList");
        allRoleList.forEach(item => {
          if (state.userInfo.roles.indexOf(item.code) !== -1) {
            code = item.code;
          }
        });
        if (!code) {
          // 非机构管理员和非平台管理员
          router.push("/401");
          return;
        }
        commit("SET_SELECTEDROLE", code);
        // if (state.userInfo.roles.indexOf("customer.admin") > -1) {
        // const data: any = await checkAccount('1', state.userInfo.userName)
        // if (data.code === 20000) {
        //   if (data.result == 0) {
        //     router.push({ name: 'check', query: { id: 2 } })
        //     return
        //   }
        // } else {
        //   router.push('/401')
        //   return
        // }
        // }
        getRoleDetailsByCode(code)
          .then(async (response: any) => {
            const permissionCodes = response.result.permissionCodes;

            if (!response.result || typeof response.result === "undefined") {
              return reject(response.message || "获取授权失败");
            }
            // roles must be a non-empty array
            if (permissionCodes === null) {
              commit("SET_ROLES", null);
              // commit("SET_SELECTEDROLE", null);
              return reject("getAuths: roles must be a non-null array!");
            }
            commit("SET_ROLES", permissionCodes);
            // commit("SET_SELECTEDROLE", permissionCodes);
            resolve({ roles: permissionCodes });
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  },
  // 获取商户信息
  getCustom({ commit, state }) {
    return new Promise((resolve, reject) => {
      getCustomerByUserId()
        .then(response => {
          commit("SET_CUSTOMERID", response.result.customerId);
          commit("SET_STOREID", response.result.merId);
          resolve({ res: response });
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // init
  initUser({ commit, state }) {
    return new Promise((resolve, reject) => {
      init()
        .then(response => {
          // commit("SET_CUSTOMERID", response.result.customerId);
          // commit("SET_STOREID", response.result.merId);
          resolve({ initRes: response });
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 通过用户ID获取商户
  getCustomReal({ commit, state }) {
    return new Promise((resolve, reject) => {
      getCustomerByUserIdReal({ userId: state.userId })
        .then(response => {
          // console.log(response);
          if (response.result) {
            commit("SET_CUSTOMERID", response.result.customerId);
            commit("SET_STOREID", response.result.merId);
            resolve({ customerId: response.result.customerId });
          } else {
            // commit("SET_CUSTOMERID", "");
            // commit("SET_STOREID", "");
            resolve({ customerId: "" });
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // 通过用户ID获取门店信息
  getStoreReal({ commit, state }) {
    return new Promise((resolve, reject) => {
      getStoreByUserIdReal({ userId: state.userId })
        .then(response => {
          // console.log(response);
          if (response?.result.length>0) {
            commit("SET_CUSTOMERID", response.result[0].customerId);
            commit("SET_STOREID", response.result[0].storeId);
            // commit("SET_STOREID", checkStore(response.result));
            commit("SET_STORELIST", response.result);
            resolve({ storeList: response.result.storeList });
          } else {
            // commit("SET_CUSTOMERID", "");
            // commit("SET_STOREID", "");
            // commit("SET_STORELIST", []);
            resolve({ storeList: [] });
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  // user login
  login({ commit }, userInfo) {
    const { username, password } = userInfo;
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then(async (response: any) => {
          const result = response;
          commit("SET_TOKEN", result.authInfo);
          setToken(result.authInfo);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logoutApi()
        .then(response => {
          console.log('responseresponse',response);
          const { result } = response;
          if (!result || typeof result === "undefined") {
            return reject("退出失败");
          }else{
            commit("SET_TOKEN", "");
            commit("SET_ROLES", []);
            commit("SET_SELECTEDROLE", "");
            sessionStorage.removeItem('Token')
            sessionStorage.removeItem('Refresh-Token')
            sessionStorage.removeItem('Auth-Code')
            resetRouter();
            gotoAuthCenter();
            console.log('token',sessionStorage.getItem('Token'));
            console.log("退出成功");
            resolve();
          }

        })
        .catch(error => {
          reject(error);
        });
    });
  },
  logoutnorequest({ commit, state, dispatch }){

    commit("SET_TOKEN", "");
    commit("SET_ROLES", []);
    commit("SET_SELECTEDROLE", "");
    sessionStorage.clear();
    resetRouter();
    window.location.href = window._CONFIG['authURL']
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit("SET_TOKEN", "");
      commit("SET_ROLES", []);
      commit("SET_SELECTEDROLE", "");
      removeToken();
      resetRouter();
      removeAuthCode();
      resolve();
    });
  },

  // dynamically modify permissions
  changeRoles({ commit, dispatch }, role) {
    return new Promise(async resolve => {
      const token = role + "-token";

      commit("SET_TOKEN", token);
      setToken(token);

      const { roles } = await dispatch("getUserInfo");

      resetRouter();

      // generate accessible routes map based on roles
      const accessRoutes = await dispatch("permission/generateRoutes", roles, {
        root: true
      });

      // dynamically add accessible routes
      router.addRoutes(accessRoutes);

      // reset visited views and cached views
      dispatch("tagsView/delAllViews", null, { root: true });

      resolve();
    });
  }
};
function checkStore(arr) {
  let storeId = arr[0].storeId;
  arr?.forEach(item => {
    if (item.status === "0") {
      storeId = item.storeId;
    }
  });
  return storeId;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
};


