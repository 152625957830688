import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const clearRouter = {
  path: "/clear",
  name: "clear",
  component: Layout,
  redirect: "/clear/list",
  meta: {
    icon: "4",
    title: "clear.clearList",
    rank: 8,
    roles: ["koms.settlement"]
  },
  children: [
    {
      path: "/clear/list",
      name: "clearList",
      component: () => import("/@/views/clear-info/index.vue"),
      meta: {
        title: "clear.clearList"
      },
      hiddenChild: false, // 导航不显示下级
      children: [
        {
          meta: { title: "clear.clearTotal" },
          path: "/clear/list/total",
          component: () => import("/@/views/clear-info/index.vue"),
          name: "clearTotal"
        }
      ]
    }
  ]
};

export default clearRouter;
