import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const remainingRouter = [
  {
    path: "/redirect",
    component: Layout,
    meta: {
      icon: "data-board",
      title: "menus.hshome",
      showLink: false,
      rank: 104
    },
    children: [
      {
        path: "/redirect/:path(.*)",
        name: "redirect",
        component: () => import("/@/layout/redirect.vue")
      }
    ]
  },
  {
    path: "/401",
    name: "401",
    component: () => import("/@/views/error-page/401.vue"),
    meta: {
      title: "menus.hslogin",
      showLink: false,
      rank: 401
    }
  },
  {
    path: "/402",
    name: "402",
    component: () => import("/@/views/error-page/402.vue"),
    meta: {
      title: "menus.hslogin",
      showLink: false,
      rank: 401
    }
  },
  {
    path: "/up-password",
    name: "up-password",
    component: () => import("/@/views/login/up-password.vue"),
    meta: {
      title: "menus.hslogin",
      showLink: false,
    }
  },
];

export default remainingRouter;
