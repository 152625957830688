import request from "../utils/request";

// 查询商户门店币种列表
export function getCurrencyList(params: Object) {
  return request({
    url: "/common/getCurrencyListByObjId",
    method: "get",
    params
  });
}
// 查询所有的角色
export function getAllRole() {
  return request({
    url: "/common/getAllRole",
    method: "get"
  });
}
