import dataReportRouter from "./dataReport";
import dataBoardRouter from "./dataBoard";
// import homeRouter from "./home";
import transactionFlowRouter from "./transactionFlow";
import clearRouter from "./clearInfo";
import accountCenterRouter from "./accountCenter";
// import orderRouter from "./order";
// 把模块引入间来
const asyncRoutes = [
  // homeRouter,
  transactionFlowRouter,
  dataReportRouter,
  dataBoardRouter,
  clearRouter,
  accountCenterRouter,
  // orderRouter
];
export default asyncRoutes;
