import { http } from "../utils/http";
import request from "../utils/request";
// 请求表格数据
// export const getTableData = (params?: object) => {
//   return http.request("get", "/getTableData", { params });
// };
// export const getTableData = () => {
//   return http.request("get", "/getTableData");
// };
// 数据初始化
export const init = () => {
  // return http.request("get", "/koms/check/init");
  return request({
    url: "/check/init",
    method: "get"
  });
};

// 获取商户当前时间
export const getStoreTime = () => {
  // return http.request("get", "/koms/check/init");
  return request({
    url: "/common/getMerchantNowTime",
    method: "get"
  });
};

export function addUser(data) {
  return request({
    baseURL: window._CONFIG["userURL"],
    url: `/account/init-password`,
    method: "PATCH",
    data: data
  });
}
