import request from "../utils/request";

// 查询商户、终端数量占比
export const getMerchantAndTerminal = () => {
  return request({
    url: "/dashboard/dashboardSsTotal",
    method: "get"
  });
};

// 查询消费金额、笔数趋势
export const getTransactionAndAmountData = (params: object) => {
  return request({
    url: "/dashboard/dashboardSsAly",
    method: "get",
    params
  });
};

// 查询消费金额、笔数占比
export const getTransactionAndAmountDayData = (params: object) => {
  return request({
    url: "/dashboard/dashboardSsTransRate",
    method: "get",
    params
  });
};

// 查询基本信息
export const getDashboardSsInfoRate = () => {
  return request({
    url: "/dashboard/dashboardSsInfoRate",
    method: "get"
  });
};

// 数据看板用户币种默认
export const getDefaultCurrency = () => {
  return request({
    url: "/Employee/defaultCurrency",
    method: "get"
  });
};

// 数据看板设置币种默认
export const setDashboardCurrency = params => {
  return request({
    url: "/Employee/dashboardCurrency",
    method: "post",
    params
  });
};

// 数据看板用户币种默认
export const getTimeZone = () => {
  return request({
    url: "/common/getAcqTimeZone",
    method: "get"
  });
};
