{
  # 商户平台
  # 数据看板
  "数据看板": "数据看板",
  "今日实时概况": "今日实时概况",
  "(按自然日统计)": "(按自然日统计)",
  "更新": "更新",
  "门店数量": "门店数量",
  "终端数量": "终端数量",
  "活跃门店": "活跃门店",
  "活跃终端数": "活跃终端数",
  "商户数量": "商户数量",
  "交易笔数": "交易笔数",
  "交易笔数占比": "交易笔数占比",
  "交易金额": "交易金额",
  "交易金额占比": "交易金额占比",
  "消费笔数趋势": "消费笔数趋势",
  "消费笔数：": "消费笔数：{value}",
  "消费金额趋势": "消费金额趋势",
  "消费金额：": "消费金额：{value}",
  "笔数": "笔数",
  "占比": "占比",
  "机构消费笔数占比": "机构消费笔数占比",
  "金额": "金额",
  "机构消费金额占比": "机构消费金额占比",
  "商户拥有的门店总数": "商户拥有的门店总数",
  "商户拥有的终端总数": "商户拥有的终端总数",
  "当日产生交易的门店数量": "当日产生交易的门店数量",
  "当日产生交易的终端数量": "当日产生交易的终端数量",
  "商户支持的所有交易币种": "商户支持的所有交易币种",
  # 数据报表
  "报表数据": "报表数据",
  "日期": "日期",
  "消费金额高峰时段分析": "消费金额高峰时段分析",
  "时段名称": "时段名称",
  "时段区间": "时段区间",
  "当天": "当天",
  "上一天": "上一天",
  "上周同天": "上周同天",
  "本周": "本周",
  "上周": "上周",
  "本月": "本月",
  "当月": "当月",
  "上月": "上月",
  "去年同月": "去年同月",
  "较上周同天": "较上周同天",
  "较上月": "较上月",
  "较上周": "较上周",
  "较昨日": "较昨日",
  "较去年同月": "较去年同月",
  "交易日排行": "交易日排行",
  "消费金额排行": "消费金额排行",
  "更多": "更多",
  "前10排名": "前10排名",
  "排名变化": "排名变化",
  "商户名称": "商户名称",
  "门店排行": "门店排行",
  "前5排名": "前5排名",
  "门店名称": "门店名称",
  "终端排行": "终端排行",
  "终端ID": "终端ID",
  "消费笔数高峰时段分析": "消费笔数高峰时段分析",
  "消费笔数排行": "消费笔数排行",
  "高峰时段": "高峰时段",
  "消费笔数": "消费笔数",
  "所有正向消费的总金额": "所有正向消费的总金额",
  "所有正向消费的总笔数": "所有正向消费的总笔数",
  "退货金额": "退货金额",
  "=消费金额/消费笔数": "=消费金额/消费笔数",
  "第某周": "第{week}周",
  "所有负向退货的总金额": "所有负向退货的总金额",
  "退货笔数": "退货笔数",
  "所有负向退货的总笔数": "所有负向退货的总笔数",
  "暂无数据": "暂无数据",
  "关闭": "关闭",
  "周一": "周一",
  "周二": "周二",
  "周三": "周三",
  "周四": "周四",
  "周五": "周五",
  "周六": "周六",
  "周日": "周日",
  "星期一": "星期一",
  "星期二": "星期二",
  "星期三": "星期三",
  "星期四": "星期四",
  "星期五": "星期五",
  "星期六": "星期六",
  "星期日": "星期日",
  "日报": "日报",
  "选择日期": "选择日期",
  "此处为结算日期": "此处为结算日期",
  "请选择日期": "请选择日期",
  "选择币种": "选择币种",
  "请选择": "请选择",
  "重置": "重置",
  "查询": "查询",
  "周报": "周报",
  "请选择周": "请选择周",
  "月报": "月报",
  "请选择月": "请选择月",
  "交易概况": "交易概况",
  "KPI概况": "KPI概况",
  "交易趋势": "交易趋势",
  "消费金额": "消费金额",
  "消费趋势分析": "消费趋势分析",
  "查看详情": "查看详情",
  "月度交易金额详情": "月度交易金额详情",
  "月度交易笔数详情": "月度交易笔数详情",
  "消费单均价": "消费单均价",
  "消费单均价趋势": "消费单均价趋势",
  "月度交易单均价详情": "月度交易单均价详情",
  "请先输入查询条件": "请先输入查询条件",
  "当天消费金额": "当天消费金额",
  "当月消费金额": "当月消费金额",
  "本周消费金额": "本周消费金额",
  "上一天消费金额": "上一天消费金额",
  "上月消费金额": "上月消费金额",
  "上周消费金额": "上周消费金额",
  "当天消费笔数": "当天消费笔数",
  "当月消费笔数": "当月消费笔数",
  "本周消费笔数": "本周消费笔数",
  "上一天消费笔数": "上一天消费笔数",
  "上月消费笔数": "上月消费笔数",
  "上周消费笔数": "上周消费笔数",
  "当月消费单均价": "当月消费单均价",
  "上月消费单均价": "上月消费单均价",
  "暂未完成对账，无法输出数据，请耐心等待！": "暂未完成对账，无法输出数据，请耐心等待！",
  "请填写所有查询条件": "请填写所有查询条件",
  # 退货记录
  "商户交易订单号": "商户交易订单号",
  "类型": "类型",
  "商户标识": "商户标识",
  "终端标识": "终端标识",
  "厂商": "厂商",
  "机型": "机型",
  "设备S/N": "设备S/N",
  "交易名称": "交易名称",
  "币种": "币种",
  "状态": "状态",
  "交易时间": "交易时间",
  "操作员": "操作员",
  "操作": "操作",
  "支付渠道": "支付渠道",
  "退货": "退货",
  "时间": "时间",
  "开始日期": "开始日期",
  "结束日期": "结束日期",
  "网关支付": "网关支付",
  "非网关支付": "非网关支付",
  "退货成功": "退货成功",
  "退货申请提交成功": "退货申请提交成功",
  "退货失败": "退货失败",
  "退货申请提交失败，请重试": "退货申请提交失败，请重试",
  "渠道处理中": "渠道处理中",
  "系统处理中": "系统处理中",
  "重发": "重发",
  "提交": "提交",
  "交易币种": "交易币种",
  "可退货金额": "可退货金额",
  "取消": "取消",
  "是否重新发起退货": "是否重新发起退货",
  "确认": "确认",
  "请输入退货金额": "请输入退货金额",
  "请输入商户交易订单号": "请输入商户交易订单号",
  "商户订单号/商户标识/商户名称/终端标识/SN": "商户订单号/商户标识/商户名称/终端标识/SN",
  "全部": "全部",
  "成功": "成功",
  "失败": "失败",
  "处理中": "处理中",
  "重新发起退货": "重新发起退货",
  "退货失败，请重试": "退货失败，请重试",
  "正常": "正常",
  "已冲正": "已冲正",
  "已撤销": "已撤销",
  "已退款": "已退款",
  "部分退款": "部分退款",

  "clear":
    {
      "number": "商户交易订单号",
      "name": "商户名称",
      "clearList": "清算信息",
      "clearTotal": "结算汇总",
      "mid": "商户号（PMR）",
      "tid": "终端号（TID）",
      "sn": "设备S/N",
      "trade": "交易名称",
      "currency": "币种",
      "total1": "交易总额",
      "amount1": "交易金额",
      "premium": "手续费",
      "total2": "清算总额",
      "amount2": "清算净额",
      "time": "交易时间",
      "person": "清算方",
      "day": "清算日期",
      "search": "搜索商户名称/PMR/TID/SN"
    },
  "accountCenter":
    {
      "name": "用户中心",
      "user": "用户管理",
      "edit": "编辑用户",
      "add": "新增用户",
      "detail": "用户详情",
      "userName": "用户名",
      "names": "姓名",
      "creator": "创建人",
      "gmtCreate": "创建时间",
      "userNames": "用户名",
      "email": "邮箱",
      "phone": "电话",
      "type": "状态",
      "role": "角色",
      "lastTime": "最后一次登录",
      "upTime": "上次登录时间",
      "upIP": "上次登录IP",
      "super": "超管",
      "unSuper": "非超管",
      "normal": "正常",
      "role1": "商户管理员",
      "role2": "门店管理员",
      "choose": "选择门店",
      "start": "开始日期",
      "end": "结束日期",
      "max64": "最长64位",
      "max32": "最长32位",
      "max20": "6-20位",
      "max100": "最长100位",
      "max128": "最长128位",
      "export1": "导出",
      "export2": "是否导出？",
      "error1": "请输入正确的邮箱",
      "error2": "请输入正确的手机号",
      "error3": "请输入大小写字母数字以及下划线"
    },

  "ok": "保存",
  "cancel": "返回",
  "confirm": "确定",
  "no": "取消",
  "close": "关闭",
  "save": "保存",
  "success": "操作成功",
  "success2": "已复制到剪切板",
  "input": "请输入",
  "select": "请选择",
  "add": "新增",
  "edt": "编辑",
  "del": "删除",
  "reset": "重置",
  "freeze": "冻结",
  "unfreeze": "解冻",
  "Inquire": "查询",
  "operate": "操作",
  "checkAll": "全选",
  "showPwd": "保存密码",
  "showPwd2": "请妥善保管您的密码",

  # navbar
  "profile": "个人中心",
  "out": "退出登录",
  "商户管理中心": "商户管理中心",
  "门店管理中心": "门店管理中心",
  "切换成中文": "切换成中文",
  "切换成英文": "切换成英文",
  "outTime": "请求超时，请重试",
  "温馨提示": "温馨提示",
  "需要您重新登录！": "需要您重新登录！",
  "首页": "首页",
  "交易分析": "交易分析",
  "经营报告": "经营报告",
  "交易流水": "交易流水",
  "退货记录": "退货记录",
  "选择币种：": "选择币种：",
  "当前默认": "当前默认",
  "设为默认币种": "设为默认币种",
  "点击折叠": "点击折叠",
  "点击展开": "点击展开",
  "关联交易": "关联交易",

  "门店名称...": "门店名称...",
  "该订单不支持退款": "该订单不支持退款",
  "订单不存在": "订单不存在",
  "是否重置用户登录密码": "是否重置用户登录密码",
  "暂无操作权限": "暂无操作权限",
  "较上一天": "较上一天",

  # 新增
  "是否解冻": "是否解冻？",
  "是否冻结": "是否冻结？",
  "门店操作员": "门店操作员",
  "用户已冻结": "用户已冻结",
  "用户已启用": "用户已启用",
  "清算汇总": "清算汇总",
  "启用中": "启用中",
  "已停用": "已停用",
  "已注销": "已注销",
  "号": "号",
  "交易日": "交易日",
  "星期": "星期",
  "排名": "排名",
  "交易日消费金额排行": "交易日消费金额排行",
  "交易日消费笔数排行": "交易日消费笔数排行",
  "终端消费金额排行": "终端消费金额排行",
  "终端消费笔数排行": "终端消费笔数排行",
  "门店消费金额排行": "门店消费金额排行",
  "门店消费笔数排行": "门店消费笔数排行",
  "原商户交易订单号": "原商户交易订单号",
  "商户管理员": "商户管理员",
  "门店管理员": "门店管理员",
  "门店超级管理员": "门店超级管理员",
  "商户超级管理员": "商户超级管理员",
  "汇总详情": "汇总详情",

  "支持币种": "支持币种",
  "响应超时，请稍后再试": "响应超时，请稍后再试",
  "返回登录": "返回登录",
  "输入金额不能大于可退货金额": "输入金额不能大于可退货金额",
  "是否发起查询": "是否发起查询?",
  "token失效或用户暂无权限，请确认后重试": "token失效或用户暂无权限，请确认后重试",
  "原商户订单号/商户标识/商户名称/终端标识/SN": "原商户订单号/商户标识/商户名称/终端标识/SN",
  "原商户订单号/商户标识/商户名称/终端标识": "原商户订单号/商户标识/商户名称/终端标识",
  "重新登录": "重新登录",
  "结算信息": "结算信息",
  "订单号不存在": "订单号不存在",
  "登录超时": "登录超时",
  "八达通": "八达通",
  "Octopus": "八达通",
  "手机": "手机",
  "请输入原商户交易订单号": "请输入原商户交易订单号",
  "商户不存在": "商户不存在",
  "门店不存在": "门店不存在",
  "八达通支付": "八达通支付",
  "八达通退款": "八达通退款",
  "八达通撤销": "八达通撤销",
  "八达通查询": "八达通查询",
  "更新密钥": "更新密钥",
  "交换数据": "交换数据",
  "用户名已存在": "用户名已存在",
  "手机号码已存在": "手机号码已存在",
  "手机号已存在":"手机号已存在",
  "邮箱已存在": "邮箱已存在",
  "请输入正数": "请输入正数",
  "请输入正确格式": "请输入正确格式",
  "回到顶部": "回到顶部",
  "对账未完成": "对账未完成",
  "返回": "返回",

  # 新增
  "是": "是",
  "否": "否",
  "分期退款": "分期退款",
  "确认分期计划": "确认分期计划",
  "获取分期计划": "获取分期计划",
  "分期状态": "分期状态",
  "分期计划ID": "分期计划ID",
  "确认分期ID": "确认分期ID",
  "分期期数": "分期期数",
  "还款周期": "还款周期",
  "分期总额": "分期总额",
  "首期本金": "首期本金",
  "首期手续费": "首期手续费",
  "末期本金": "末期本金",
  "末期手续费": "末期手续费",
  "分期总手续费": "分期总手续费",
  "最多两位小数": "最多两位小数",
  "正在努力加载中...": "正在努力加载中...",
  "已经撤销的交易无法发起退款": "已经撤销的交易无法发起退款",
  "max17": "最长17位",
  "max48": "最长48位",
  "max1024": "最长1024位",

# 在线订单
  "订单管理": "订单管理",
  "Online Order": "Online Order",
  "System Reference No.": "System Reference No.",
  "Bill to": "Bill to",
  "Order No.": "Order No.",
  "Order Status": "Order Status",
  "Order Amount": "Order Amount",
  "Creation Time": "Creation Time",
  "展开": "展开",
  "收拢": "收拢",


  "请输入金额": "请输入金额",
  "请输入(不包含特殊字符)": "请输入(不包含特殊字符)",
  "是否删除用户": "是否删除用户？",



  "修改初始密码": "修改初始密码",
  "您已成功登录Klond 商户服务平台，为保证您的账号安全，您需要修改初始密码": "您已成功登录Klond 商户服务平台，为保证您的账号安全，您需要修改初始密码",
  "新密码": "新密码",
  "请输入新密码": "请输入新密码",
  "确认密码": "确认密码",
  "请确认密码": "请确认密码",
  "格式错误": "格式错误",
  "请再次输入新密码": "请再次输入新密码",
  "两次输入不一致": "两次输入不一致",
  "密码修改成功": "密码修改成功",
  "正在跳转至登录界面": "正在跳转至登录界面",
  "密码最小长度为8位": "密码最小长度为8位",
  "密码最大长度为16位": "密码最大长度为16位",
  "必须包含字母、数字和特殊符号至少两种": "必须包含字母、数字和特殊符号至少两种",
  "可使用特殊字符": "可使用特殊字符",
  "请输入": "请输入",
  "vPlanRef": "Plan Ref",
  # "externalTransactionRef": "External Transaction Ref",
  "externalTransactionRef": "分期MID",
  "vPlanID": "Plan ID",
  "vPlanAcceptanceId": "PlanAcceptance ID",
  "退款成功": "退款成功",
  "退款失败": "退款失败",
  "待审核": "待审核",
  "审核驳回": "审核驳回",
  "审核通过": "审核通过",
  "渠道": "渠道",
  "获取时区失败": "获取时区失败",
  "outtransactionno": "流水号",
  "原商户订单号/商户标识/商户名称/终端标识/流水号/分期MID": "原商户订单号/商户标识/商户名称/终端标识/流水号/分期MID",
}
