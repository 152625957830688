import { $t } from "/@/plugins/i18n";
const Layout = () => import("/@/layout/index.vue");

const accountCenterRouter = {
  path: "/account-center",
  name: "accountCenter",
  component: Layout,
  redirect: "/account-center/user",
  meta: {
    icon: "5",
    title: "accountCenter.name",
    rank: 9,
    roles: ["koms.employee"]
  },
  children: [
    {
      path: "/account-center/user",
      name: "accountCenterUser",
      component: () => import("/@/views/account-center/index.vue"),
      meta: {
        title: "accountCenter.user"
      },
      hiddenChild: false, // 导航不显示下级
      children: [
        {
          meta: { title: "accountCenter.user" },
          path: "/account-center/user",
          component: () => import("/@/views/account-center/index.vue"),
          name: "accountCenterUser"
        },
        {
          meta: { title: "accountCenter.detail", showLink: false },
          path: "/account-center/detail",
          component: () => import("/@/views/account-center/add-or-edit.vue"),
          name: "addUser"
        },
      ]
    }
  ]
};

export default accountCenterRouter;
